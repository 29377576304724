import React, { useRef, useState, useMemo, useEffect, useDeferredValue } from 'react';
import styled from 'styled-components';
import { DraggableBox } from '../../UI/DraggableBox';
import { useDispatch, useSelector } from 'react-redux';
import { addModuleData } from '../../../redux/project';

export const getEmojiDefault = i => {
  let coefX = 0;
  let coefY = i;

  if (i > 2) {
    coefX = 15;
    coefY -= 3;
  }

  return {
    name: `Emoji ${i + 1}`,
    imageData: null,
    position: [69 + coefX, 25 + coefY * 25]
  };
};

export const EmojiVisualModule = ({
  moduleName,
  number,
  id,
  className,
  onUpdateFields,
  onRemoveModule
}) => {
  const dispatch = useDispatch();
  const data =
    useSelector(state => state.project.moduleList).find(module => module.id === id)?.data || {};

  const [backgroundColor, setBackgroundColor] = useState(data.backgroundColor || '#fff');
  const [backgroundData, setBackgroundData] = useState(data.backgroundData || null);
  const [fontFamily, setFontFamily] = useState(data.fontFamily || 'Poppins-Regular');
  const [boxText, setBoxText] = useState(data.boxText || 'Changing placeholder text here');
  const [textBoxPosition, setTextBoxPosition] = useState(data.textBoxPosition || [4, 5]);
  const [textBoxSize, setTextBoxSize] = useState(data.textBoxSize || [60, 15]);
  const [textBoxColor, setTextBoxColor] = useState(data.textBoxColor || '#333');
  const [textBoxFontSize, setTextBoxFontSize] = useState(data.textBoxFontSize || 3);
  const [boxPosition, setBoxPosition] = useState(data.boxPosition || [4, 25]);
  const [boxSize, setBoxSize] = useState(data.boxSize || [60, 70]);
  const [backgroundBox, setBackgroundBox] = useState(data.backgroundBox || null);
  const [maxGridX, setMaxGridX] = useState(data.maxGridX || 0);
  const [maxGridY, setMaxGridY] = useState(data.maxGridY || 0);
  const [emojiList, setEmojiList] = useState(
    data.emojiList || [...Array(3)].map((item, i) => getEmojiDefault(i))
  );
  const [emojiSize, setEmojiSize] = useState(data.emojiSize || [10, 20]);
  const [emojiNameShow, setEmojiNameShow] = useState(data.emojiNameShow || false);
  const [emojiNameColor, setEmojiNameColor] = useState(data.emojiNameColor || '#000');
  const [emojiNameFontSize, setEmojiNameFontSize] = useState(data.emojiNameFontSize || 5);
  const [optionNameList, setOptionNameList] = useState(data.optionNameList || []);
  const [optionPosXList, setOptionPosXList] = useState(data.optionPosXList || [...Array(optionNameList.length)].map(i => 0));
  const [optionPosYList, setOptionPosYList] = useState(data.optionPosYList || [...Array(optionNameList.length)].map(i => 0));
  const [optionWidthList, setOptionWidthList] = useState(data.optionWidthList || [...Array(optionNameList.length)].map(i => 20));
  const [optionHeightList, setOptionHeightList] = useState(data.optionHeightList || [...Array(optionNameList.length)].map(i => 20));
  const [optionImageList, setOptionImageList] = useState(data.optionImageList || []);

  const container = React.useRef(null);
  const boxTextRef = useRef(boxText);

  const [textBoxLinePosition, setTextBoxLinePosition] = useState([0, 0]);

  const [moduleWidthCoef, setModuleWidthCoef] = useState(0);
  const defferedCoef = useDeferredValue(moduleWidthCoef);

  const memoBackgroundImage = useMemo(() => {
    return backgroundData ? `url('${backgroundData.image}')` : null;
  }, [backgroundData]);

  const memoBackgroundBox = useMemo(() => {
    return backgroundBox ? `url('${backgroundBox.image}')` : null;
  }, [backgroundBox]);

  function removeModule() {
    onRemoveModule(id);
  }

  function updateFields() {
    onUpdateFields({
      moduleName,
      number,
      id,
      backgroundColor,
      setBackgroundColor,
      backgroundData,
      setBackgroundData,
      fontFamily,
      setFontFamily,
      textBoxColor,
      setTextBoxColor,
      textBoxFontSize,
      setTextBoxFontSize,
      backgroundBox,
      setBackgroundBox,
      emojiList,
      setEmojiList,
      emojiSize,
      setEmojiSize,
      emojiNameShow,
      setEmojiNameShow,
      emojiNameColor,
      optionNameList,
      setOptionNameList,
      optionPosXList,
      setOptionPosXList,
      optionPosYList,
      setOptionPosYList,
      optionWidthList,
      setOptionWidthList,
      optionHeightList,
      setOptionHeightList,
      optionImageList,
      setOptionImageList,
      setEmojiNameColor,
      emojiNameFontSize,
      setEmojiNameFontSize,
      removeModule
    });
  }

  useEffect(updateFields, [
    backgroundColor,
    backgroundData,
    fontFamily,
    textBoxColor,
    textBoxFontSize,
    backgroundBox,
    emojiList,
    emojiSize,
    emojiNameShow,
    emojiNameColor,
    optionNameList,
    optionPosXList,
    optionPosYList,
    optionWidthList,
    optionHeightList,
    optionImageList,
    emojiNameFontSize
  ]);

  useEffect(() => {
    dispatch(
      addModuleData({
        id: id,
        data: {
          backgroundColor,
          backgroundData,
          fontFamily,
          textBoxPosition,
          textBoxSize,
          boxText,
          textBoxColor,
          textBoxFontSize,
          boxPosition,
          boxSize,
          backgroundBox,
          maxGridX,
          maxGridY,
          emojiList,
          emojiSize,
          emojiNameShow,
          emojiNameColor,
          optionNameList,
          optionPosXList,
          optionPosYList,
          optionWidthList,
          optionHeightList,
          optionImageList,
          emojiNameFontSize
        }
      })
    );
  }, [
    backgroundColor,
    backgroundData,
    fontFamily,
    textBoxPosition,
    textBoxSize,
    boxText,
    textBoxColor,
    textBoxFontSize,
    boxPosition,
    boxSize,
    backgroundBox,
    maxGridX,
    maxGridY,
    emojiList,
    emojiSize,
    emojiNameShow,
    emojiNameColor,
    optionNameList,
    optionPosXList,
    optionPosYList,
    optionWidthList,
    optionHeightList,
    optionImageList,
    emojiNameFontSize
  ]);

  function onChangeSize(e) {
    let value = container.current.offsetWidth / window.innerWidth;
    setModuleWidthCoef(value);
  }

  function updateMaxGrid() {
    let dropzone = container.current.querySelector('.emoji-box');
    let emoji = container.current.querySelector('.emoji-img');
    if (dropzone && emoji) {
      dropzone = dropzone.getBoundingClientRect();
      emoji = emoji.getBoundingClientRect();

      if (dropzone.width && dropzone.height && emoji.width && emoji.height) {
        setMaxGridX(Math.round((dropzone.width / emoji.width) * 2));
        setMaxGridY(Math.round((dropzone.height / emoji.height) * 2));
      } else {
        setMaxGridX(0);
        setMaxGridY(0);
      }
    } else {
      setMaxGridX(0);
      setMaxGridY(0);
    }
  }

  useEffect(updateMaxGrid, [boxSize, emojiNameShow, emojiSize]);

  useEffect(() => {
    onChangeSize();
    window.addEventListener('resize', onChangeSize);

    return function () {
      window.removeEventListener('resize', onChangeSize);
    };
  }, []);

  function addProp(obj, i) {
    const arr = [...emojiList];
    arr[i] = {
      ...emojiList[i],
      ...obj
    };
    setEmojiList(arr);
  }

  return (
    <StyledModule
      fontFamily={fontFamily}
      backgroundImage={memoBackgroundImage}
      ref={container}
      className={className}
      onClick={updateFields}>
      <DraggableBox
        position={textBoxPosition}
        setPosition={setTextBoxPosition}
        sizes={textBoxSize}
        setSizes={setTextBoxSize}
        linePosition={textBoxLinePosition}
        setLinePosition={setTextBoxLinePosition}
        parentRef={container}>
        <StyledText
          font={textBoxFontSize * defferedCoef}
          color={textBoxColor}
          onInput={e => setBoxText(e.target.innerHTML)}
          contentEditable
          dangerouslySetInnerHTML={{ __html: boxTextRef.current }}></StyledText>
      </DraggableBox>

      <DraggableBox
        className='emoji-box'
        position={boxPosition}
        setPosition={setBoxPosition}
        sizes={boxSize}
        setSizes={setBoxSize}
        parentRef={container}
        backgroundImage={memoBackgroundBox}
      >
        {optionImageList.map((item, i) => {
          return (
            <StyledOptionCell
              key={`emoji_option_cell${id}_${i}`}
              width={optionWidthList[i]}
              height={optionHeightList[i]}
              top={optionPosYList[i]}
              left={optionPosXList[i]}
            >
              <img src={item?.image} alt={item?.name}/>
            </StyledOptionCell>
          );
        })}
      </DraggableBox>
      {emojiList.map((item, i) => {
        return (
          <DraggableBox
            key={`emoji_${id}_${i}`}
            position={item.position}
            setPosition={position => addProp({ position }, i)}
            sizes={emojiSize}
            grid={[16, 9]}
            parentRef={container}>
            <StyledEmojiContainer>
              {item.imageData && (
                <img
                  onLoad={updateMaxGrid}
                  className='emoji-img'
                  height={emojiNameShow ? '70%' : '100%'}
                  src={item.imageData.image}
                />
              )}
              {emojiNameShow && (
                <StyledEmojiName color={emojiNameColor} font={emojiNameFontSize * defferedCoef}>
                  {item.name}
                </StyledEmojiName>
              )}
            </StyledEmojiContainer>
          </DraggableBox>
        );
      })}
    </StyledModule>
  );
};

const StyledModule = styled.div`
  position: absolute;
  top: 5%;
  left: 5%;
  bottom: 5%;
  right: 5%;
  background: ${props => (props.backgroundImage ? props.backgroundImage : '#fff')};
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  box-shadow: 0 0 2px 1px rgba(0 0 0 / 30%);
  font-family: ${props => props.fontFamily};
  &.active {
    box-shadow: 0 0 1px 2px #53a451;
  }
`;

const StyledText = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  background: transparent;
  font-size: ${props => 0.9 * props.font + 'vw'} !important;
  line-height: 1.2;
  color: ${props => props.color};
`;

const StyledEmojiContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const StyledEmojiName = styled.div`
  height: 30%;
  color: ${props => props.color};
  font-size: ${props => 0.4 * props.font + 'vw'};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledOptionCell = styled.div`
  width: ${props => (`${props.width}%`)};
  height: ${props => (`${props.height}%`)};
  position: absolute;
  top: ${props => (`${props.top}%`)};
  left: ${props => (`${props.left}%`)};
  border: 1px #ccc dashed;
  overflow: hidden;
  text-align: center;
  img {
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
`;
